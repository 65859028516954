import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import React from 'react'
import get from 'lodash/get'
import blogPost from './blog-post.module.css'
import Button from '../components/button'

class ContactSuccess extends React.Component {
  render() {
    const exhibitions = get(this, 'props.data.allContentfulExhibition.edges')
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')

    return (
      <Layout location={this.props.location} exhibitions={exhibitions}>
        <Helmet title={`Contact | ${siteTitle}`} />
        <div className={blogPost.wrapper}>
          <div className={blogPost.leftColumn}>
            <h1 className={blogPost.title}>Contact</h1>
            <p>
              Thank you contacting Tommy Clancy, we'll be in touch soon.
            </p>
          </div>
          <div className={blogPost.rightColumn}>
            <form
              method="post"
              netlify-honeypot="bot-field"
              data-netlify="true"
              name="contact"
              action="/contact-success"
            >
              <input type="hidden" name="form-name" value="contact"></input>
              <p>
                <label>
                  Your Name: <input required type="text" name="name" />
                </label>
              </p>
              <p>
                <label>
                  Your Email: <input required type="email" name="email" />
                </label>
              </p>
              <p>
                <label>
                  Message: <textarea name="message"></textarea>
                </label>
              </p>
              <p>
                <Button type="submit">Submit</Button>
              </p>
            </form>
          </div>
        </div>
      </Layout>
    )
  }
}

export default ContactSuccess

export const pageQuery = graphql`
  query ContactSuccessPage {
    site {
      siteMetadata {
        title
      }
    }

    allContentfulExhibition {
      edges {
        node {
          title
          slug
        }
      }
    }
  }
`
